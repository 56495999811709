import React from "react";
import "./testimonial.css";
import quote from "../images/quote.png";

const TESTIMONIAL = ({ allprops }) => {
  return (
    <div ref={allprops.testimonialcontainer} id="testimonialcontainer">
      <div id="theading">
        <span>Testimonials</span>
        <span>What our customers say</span>
      </div>
      <div id="tcard">
        <div className="cardchildt">
          <img src={quote} alt="imgold" />
          <span>
            Pragmatic Investment Solutions partnered with Blue Horse Interiors
            and worked with Ved Prakash Sharma to find an innovative solution
            for our office expansion. Ved's expertise, flexibility, and deep
            product knowledge ensured a successful renovation within our budget
            and timeframe. We highly recommend his services and look forward to
            future projects together.
          </span>
          <div className="name">
            <span>Pragmatic</span>
            <span>Investment Solutions</span>
          </div>
        </div>
        <div className="cardchildt">
          <img src={quote} alt="imgold" />
          <span>
            I referred Ved Prakash Sharma of Blue Horse Interiors for an
            interior fit-out project. Ved and his team delivered exceptional,
            Vastu-compliant office solutions, impressing both our client and top
            officials. I highly recommend Ved for future projects.
          </span>
          <div className="name">
            <span>Rajesh Madan</span>
            <span>ceo, the Monks</span>
          </div>
        </div>
        <div className="cardchildt">
          <img src={quote} alt="imgold" />
          <span>
            I Highly recommend Blue Horse Interiors for interior work. They
            transformed a bare shell office into a mesmerizing and happy space,
            surpassing expectations. Their practical solutions, cost reduction,
            flawless work, and professional behavior impressed the clients. The
            office promotes communication, productivity, and comfort, leaving a
            positive impact on Orionis IP's future.
          </span>
          <div className="name">
            <span
              style={{
                fontFamily: "nova",
                fontSize: "0.9rem",
                color: "grey",
              }}
            >
              Best Regards
            </span>
            <span
              style={{
                fontFamily: "'Dancing Script', cursive",
                fontSize: "1.4rem",
                color: "#569bd5",
              }}
            >
              Saurabh
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TESTIMONIAL;
