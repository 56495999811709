import React, { useRef, useState, useEffect } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";
import dot from '../images/dot.png'
import send from '../images/send.png'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

const CONTACT = ({ allprops }) => {
  const [namevalue, setnamevalue] = useState();
  const [emailvalue, setemailvalue] = useState();
  const [messagevalue, setmessagevalue] = useState();
  const btnref = useRef(null);

  let string = `Ready to transform your space? Let's collaborate and create a design
  that reflects your style and personality. Reach out to us today!`
let contacttext = string.split("").map((word) => 
  <span className="contacttext">{word}</span>
);

useEffect(() => {
  const contacttext = document.querySelectorAll(".contacttext");
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: contacttext,
      scrub: 1,
      start: "top 70%",
      end: "top 30%",
    }
  })
  tl.to(contacttext, {color: '#fff', ease: 'none', stagger: .1})
}, []);

  const handlesubmit = (e) => {
    e.preventDefault();

    if (
      namevalue &&
      namevalue.trim() !== "" &&
      emailvalue &&
      emailvalue.trim() !== "" &&
      messagevalue &&
      messagevalue.trim() !== ""
    ) {
      emailjs
        .sendForm(
          "service_lx6sc35",
          "template_5fhtm7k",
          e.target,
          "ajETyNfUSNIhmZHdA"
        )
        .then((res) => {
          console.log(res);
          alert("Sent Successfully");

          document.getElementById("email").value = "";
          document.getElementById("name").value = "";
          document.getElementById("message").value = "";
        })
        .catch((err) => console.log(err));
    } else {
      alert("Fill all the Fields");
    }


  };

  return (
    <>
      <div id="contactpage" ref={allprops.contactcontainer}>
        <div id="contactheading">
          <span>Get in touch</span>
          <span>
            {contacttext}
          </span>
        </div>
        <div id="Email-Form-Container">

        <div id="emailcontainer">
          <a
            target="_blank"
            href="https://mail.google.com/mail/?view=cm&fs=1&to=bluehorseinteriors@gmail.com"
          > <div className="dot" src={dot} alt=""></div>
            bluehorseinteriors@gmail.com
          </a>
          <a><div className="dot" src={dot} alt=""></div>+91 9810507735</a>
          <a>
            {" "}
            <div className="dot" src={dot} alt=""></div> 128, Near Udmi Ram Chowk, Nirmal <br /> Dham Road, Village Chhawla, New <br />
            Delhi-110071
          </a>
        </div>
        <form onSubmit={handlesubmit} id="contactform">
          <input
            onChange={(e) => setnamevalue(e.target.value)}
            placeholder="Your name"
            id="name"
            name="user_name"
          />
          <input
            onChange={(e) => setemailvalue(e.target.value)}
            type="email"
            placeholder="Your email"
            name="user_email"
            id="email"
          />
          <input
            onChange={(e) => setmessagevalue(e.target.value)}
            placeholder="Tell us about your project"
            name="message"
            id="message"
          />
          <button ref={btnref} type="submit" id="submitbtn">
            <img id="send" src={send} alt="" />
          </button>
        </form>
        </div>

        <svg
          id="circulartext"
          viewBox="0 0 300 300"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            id="SunCatcherStudio"
            fill="none"
            stroke="transparent"
            d="M 32.550491,148.48008 A -108.15144,-108.15144 0 0 1 140.70194,40.328644 -108.15144,-108.15144 0 0 1 248.85338,148.48008 -108.15144,-108.15144 0 0 1 140.70194,256.63153 -108.15144,-108.15144 0 0 1 32.550491,148.48008 Z"
          />
          <text id="ctxt" fontFamily="Raleway">
            <textPath xlinkHref="#SunCatcherStudio" side="left" startOffset="5">
              contact * contact * contact * contact *
            </textPath>
          </text>
        </svg>
      </div>
    </>
  );
};

export default CONTACT;
