import React from "react";
import "./whatwedo.css";

const WHATWEDO = ({ props }) => {
  return (
    <>
      <div id="whatwedocontainer">
        <div id="weheading">
          <span>
            What <span style={{ color: "#569bd5" }}>we</span> do
          </span>
          <span>
            We welcome you to explore our services and reach out to us to
            discuss your needs and ideas. Our team is dedicated to assisting you
            in turning your vision into a reality.
          </span>
        </div>
        <div id="works">
          <div>
            <span>01.</span>
            <span>Residential & Commercial Interior Design</span>
            <span>
              Designing is the art of creating aesthetic and functional spaces
            </span>
          </div>
          <div>
            <span>02.</span>
            <span>Design & Build</span>
            <span>
              Design-build: Single entity handles both design and execution.
            </span>
          </div>
          <div>
            <span>03.</span>
            <span>Space Planning</span>
            <span>
              We assess the purpose, functional needs, and initial layout of
              spaces in homes or commercial establishments.
            </span>
          </div>
          <div>
            <span>04.</span>
            <span>General Contracting</span>
            <span>
              We commit to timely, budget-friendly project delivery with
              top-notch quality and complete process transparency
            </span>
          </div>
          <div>
            <span>05.</span>
            <span>Renovation</span>
            <span>
              We enhance the aesthetics and ambiance of Residential & Commercial
              spaces
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WHATWEDO;
