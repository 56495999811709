import React from 'react'
import { useRef, useState,useEffect } from "react";

import './footer.css'
import instagram from '../images/instagram.png'
import whatsapp from '../images/whatsapp.png'
import linkedin from '../images/linkedin.png'


const FOOTER = ({allprops}) => {

  
  const a = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      allprops.setRedirect(false)
      // allprops.navigate('/')
      allprops.aboutcontainer.current.scrollIntoView()
    },300)
  }
  const p = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      allprops.setRedirect(false)
      // allprops.navigate('/')
      allprops.projectcontainer.current.scrollIntoView()
    },300)
  }
  const c = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      allprops.setRedirect(false)
      // allprops.navigate('/')
      allprops.contactcontainer.current.scrollIntoView()
    },300)
  }
  const t = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      // allprops.navigate('/')
      allprops.testimonialcontainer.current.scrollIntoView()
    },300)
  }
  return (
    <>
    <div id='footercontainer'>
      <div id='FooterHeading'><span><span style={{color: '#2187c9'}}>BLUE</span> HORSE <br /> INTERIORS</span> </div>
      <div id='pages'>
        <span>Quick Links</span>
        <span className='Links' onClick={a}>About</span>
        <span className='Links' onClick={p}>Projects</span>
        <span className='Links' onClick={t}>Testimonials</span>
        <span className='Links' onClick={c}>Contact</span>
      </div>
      <div id='socials'>
        <span>Socials</span>
        <a href='https://www.linkedin.com/in/ved-prakash-sharma-59ba49101' target='_blank'>LinkedIn</a>
        <a href='https://www.instagram.com/bluehorseinteriors/?igshid=NGExMmI2YTkyZg%3D%3D' target='_blank'>Instagram</a>
        <a href='https://wa.me/919810507735' target='_blank'>WhatsApp</a>
      </div>
    </div>
      <div id='copyRight'>
        <span> © 2023 <span style={{color: '#569bd5'}}>Blue Horse</span></span>
        <span>All Rights Reserved</span>
        <span>Website by Harshit</span>
         </div>
</>
  )
}

export default FOOTER 