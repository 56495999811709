import React from "react";
import { useRef, useState } from "react";
import "./navbar.css";
import arrow from "../images/arrow.png"
import gsap from "gsap";


const NAVBAR = ({ allprops }) => {
  const [menu, setmenu] = useState(false)
  const checkbox = useRef(null)

  const aboutclick = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      allprops.setRedirect(false)
      allprops.aboutcontainer.current.scrollIntoView()
    },300)
setmenu(false)
}
const projectclick = () =>{
  allprops.setLoading(true)
  setTimeout(()=>{
    if(allprops.Redirect){
      allprops.navigate('/')
    }
  },250)
  setTimeout(()=>{
    allprops.setRedirect(false)
    allprops.projectcontainer.current.scrollIntoView()
  },300)
  setmenu(false)
}

const testimonialclick = () =>{
  allprops.setLoading(true)
  setTimeout(()=>{
    if(allprops.Redirect){
      allprops.navigate('/')
    }
  },250)
  setTimeout(()=>{
    allprops.setRedirect(false)
    allprops.testimonialcontainer.current.scrollIntoView()
  },300)
    setmenu(false)
  }
  const contactclick = () =>{
    allprops.setLoading(true)
    setTimeout(()=>{
      if(allprops.Redirect){
        allprops.navigate('/')
      }
    },250)
    setTimeout(()=>{
      allprops.setRedirect(false)
      allprops.contactcontainer.current.scrollIntoView()
    },300)
    setmenu(false)
    
  }
  
  return (
    <>
      <div  id="navbar">
          <div id="menu" onClick={()=> setmenu(!menu)}><span style={{transform: menu ? 'translateY(-100%)' : 'translateY(0%)'}}>menu</span><span style={{transform: menu ? 'translateY(-100%)' : 'translateY(0%)'}}>X</span></div>
        <div id="menucontainer" style={{scale: menu ? '1': '0', opacity: menu ? '1': '0'}}>
          <div className="menuLinks" onClick={aboutclick}><span>About</span><img src={arrow} alt="" /> </div>
          <div className="menuLinks" onClick={projectclick} ><span>Projects</span><img src={arrow} alt="" /> </div>
          <div className="menuLinks" onClick={testimonialclick}><span>Testimonials</span><img src={arrow} alt="" /> </div>
          <div className="menuLinks" onClick={contactclick}><span>Contact</span><img src={arrow} alt="" /> </div>
          <div  id="get" >
            <span>Get in touch</span>
            <a target="_blank"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=bluehorseinteriors@gmail.com">bluehorseinteriors@gmail.com</a>
          </div>
        </div>
      </div>
<div style={{opacity: menu ? '1':'0', zIndex: menu ? '59':'-59'}} id="menuBg"></div>
     
    </>
  );
};
export default NAVBAR;
