import React, { useState,  useRef, useEffect } from "react";
import "./style.css";
import {  Route, Routes, useNavigate } from "react-router-dom";
import HOME from "./Components/HOME";
import PROJECTS from "./Components/PROJECTS";
import ABOUT from "./Components/ABOUT";
import WHATWEDO from "./Components/WHATWEDO";
import CONTACT from "./Components/CONTACT";
import NAVBAR from "./Components/NAVBAR";
import TESTIMONIAL from "./Components/TESTIMONIAL";
import CLIENT from "./Components/CLIENT";
import SERVICES from "./Components/SERVICES";
import FOOTER from "./Components/FOOTER";
import A2 from "./Components/A2";
import GANESHA from "./Components/ProjectPages/Ganesha"
import HERO from "./Components/ProjectPages/Hero";
import NIPMAN from "./Components/ProjectPages/Nipman";
import VERMA from "./Components/ProjectPages/Verma";
import FARM from "./Components/ProjectPages/Farm";
import LoadingPage from "./Components/Loading";
import bg1 from './images/bg1.jpeg'
import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)


function App() {

  const lenis = new Lenis();
  
  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }
  
  requestAnimationFrame(raf)



  const navigate = useNavigate()

  const [whatclass, setwhatclass] = useState('initialclass')
  const view = useRef(null)

  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);
  const projectcontainer = useRef(null)
const aboutcontainer = useRef(null)
const testimonialcontainer = useRef(null)
const contactcontainer = useRef(null)
const scrollsmooth = useRef(null)
const home = useRef(null)
const [Loading, setLoading] = useState(false)
const [Redirect, setRedirect] = useState(false)





  let allprops = {
    Redirect,
    setRedirect,
    Loading, setLoading,
    scrollsmooth,
    projectcontainer,
    aboutcontainer,
testimonialcontainer,
    navigate,
    contactcontainer,
    whatclass, setwhatclass,
    home,
    view,
    setX,
    setY,
  };




console.log(Redirect)


  return (
    <>

      <div onClick={()=>{window.location.reload(); navigate('/'); setTimeout(()=>{window.scrollTo(0,0)},500) }} id="logo"></div>
          <NAVBAR allprops={allprops}/> 
     <LoadingPage allprops={allprops}/>
        
        <A2/>
        <Routes>

         <Route path="/" element={
           <div id="mainwrapper"> 
        <HOME allprops={allprops} />
        <ABOUT allprops={allprops} />
        <WHATWEDO allprops={allprops} />
        <PROJECTS allprops={allprops} />
        <SERVICES allprops={allprops}/>
        {/* <CLIENT allprops={allprops}/> */}
        <TESTIMONIAL allprops={allprops}/>
        <CONTACT allprops={allprops}/>

        </div>
        }/>

      <Route path="/ganesha" element={<GANESHA  allprops={allprops}/>}/> 
      <Route path="/nipman" element={<NIPMAN  allprops={allprops}/>}/> 
      <Route path="/farm" element={<FARM  allprops={allprops}/>}/> 
      <Route path="/hero" element={<HERO  allprops={allprops}/>}/> 
      <Route path="/verma" element={<VERMA  allprops={allprops}/>}/> 


      </Routes>



      <FOOTER allprops={allprops}/>

  
    </>
  );
}

export default App;
