import React, { useEffect } from 'react'
import './about.css'
import aboutvideo from "../images/bgvideo.mp4"
import arrow from "../images/arrow.png"
import founderimg from "../images/Myproject.jpg"
import bni from "../images/bni-logo.png"
import npc from "../images/npc-logo.png"

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

const ABOUT = ({allprops}) => {

  let string = ' Our adept team of highly skilled craftsmen is committed to upholding impeccable standards of quality and workmanship. This commitment is bolstered by our dedicated support staff, ensuring punctual project delivery.'
  let singleString = string.split("").map((word)=><span className='letters'>{word}</span>)

useEffect(()=>{
const letters = document.querySelectorAll(".letters")
let tl = gsap.timeline({
  scrollTrigger:{
    trigger: letters,
    scrub: 1,
    start: 'top 70%',
    end: 'bottom 20%',
    // markers: true
  }
})
tl.fromTo(letters, {color: '#2d2d2d'},{color: '#fff', ease: 'none', stagger: .1})
},[])

  return (
    <>

        
     <div  id="aboutwrapper" ref={allprops.aboutcontainer}>
        <div id="aboutheading">
          About Us
        </div>
    </div>
    <div id="CompanyInfo">
      <span>
      {singleString}
      </span>
      <div id='introWrapper'>
      <img src={arrow} alt="" />
      <span>
      Established in 2012, Blue Horse Interiors stands as a distinctive
        Interior Fitout company located in Delhi. Our core commitment revolves
        around delivering unparalleled services, Top-notch quality while
        maintaining a competitive pricing structure. Specializing in Turnkey
        contracts, we offer comprehensive solutions to cater to all interior
        needs.
      </span>
      <span>
      We are dedicated to ensuring that all endeavors are executed with
        precision and accuracy from the outset. This commitment not only
        optimizes time utilization but also results in substantial cost savings,
        aligning with the adage that time is indeed a valuable economic
        resource.
      </span>
      </div>
    </div>

      <div id="founderwrapper">
        <img src={founderimg} alt="" id="founder" />
        <span>Meet</span>
        <span>Ved Prakash Sharma</span>
        <span>Founder</span>
        <span>During my career, I have sucessfully executed interior projects for renowned corporate houses like, Oriflame, JK Lakshmi Cement, Cholamandlam DBS, Luxor, Honeywell, HCL, Havells, ANZ Grindlays Bank and many more. I am a Senior member of BNI Delhi Central and Director of Workshop Club NPC. I have worked as a Project Manager for Delta Space Solutions Pvt. Ltd. New Delhi (2002-2015) and as a Project Executive for Vijaya Furniture, New Delhi (2000-2002), Architecture Interior Management, New Delhi (1998- 2000).</span>
        <div id="badges">
          <img src={bni} alt=""/>
          <img src={npc} alt=""/>
        </div>
      </div>

    {/* <div id="awardswrapper">
      <span id="awardsheading">Recognition</span>
      <div id="awards">
        <div>
          <span>Year</span>
          <span>Awards & Certificate</span>
        </div>
        <div>
          <span>2023</span>
          <span>AAGAAZ icon member award</span>
        </div>
        <div>
          <span>2023</span>
          <span>Certificate of Blue Badge member</span>
        </div>
        <div>
          <span>2022</span>
          <span>Club NPC Noida Club</span>
        </div>
        <div>
          <span>2022</span>
          <span>AAGAAZ - Stand Out Performer</span>
        </div>
        <div>
          <span>2022</span>
          <span>BNI High Flyer Chapter</span>
        </div>
        <div>
          <span>2022</span>
          <span>Certificate of Appreciation - BNI</span>
        </div>
      </div>
    </div> */}
    </>
  )
}

export default ABOUT