import React from 'react'
import '../gallery.css'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState } from 'react';
import heroimg1 from '../Projectimages/hero/heroimg1.jpeg'
import heroimg2 from '../Projectimages/hero/heroimg2.jpeg'
import heroimg3 from '../Projectimages/hero/heroimg3.jpeg'
import heroimg4 from '../Projectimages/hero/heroimg4.jpeg'
import heroimg5 from '../Projectimages/hero/heroimg5.jpeg'
gsap.registerPlugin(ScrollTrigger); 

const Hero = ({allprops}) => {
 
  useEffect(()=>{
    allprops.setRedirect(true)
    return ()=>{
      allprops.setRedirect(false)
    }
  },[])
  useEffect(()=>{
    window.scrollTo(0,0)

const Images_img_1 = document.querySelector('#Images-img-1')
const firstImage = document.querySelector('.firstImage')
let tl = gsap.timeline({
  scrollTrigger:{
    trigger: Images_img_1,
    scrub: 1,
    start: 'top 50%',
    end: 'bottom top'
  }
})
tl.to(firstImage,{scale: 1.3, ease:'none'})
  },[])

  const [PreviewImage, setPreviewImage] = useState()
  let arr = [heroimg2, heroimg1, heroimg4, heroimg5]

  const handleClick = (index) =>{
    setPreviewImage(index)
    let fullView = document.querySelector('#fullView')
    gsap.to(fullView,{opacity: '1', pointerEvents: 'all'})
  }
  const handleClose = () =>{
    let fullView = document.querySelector('#fullView')
    gsap.to(fullView,{opacity: '0', pointerEvents: 'none'})
    console.log('working')
  }


      return (
        <>
        <div id="ImagesContainer">
          <div id="ProjectDescription">
            <span>Brij Hero Mathura</span>
            <span>Commercial</span>
          </div>
          <div id="Images-img-1"><img className='firstImage' src={heroimg3} alt="" /></div>
          <div  id="HeroImages">
      {
        arr.map((item, index)=>{
          return(
            <div onClick={()=>handleClick(index)} className="heroimages"> <div>View</div> <img className='imgHero' src={item} alt="" /></div>
          )
        })
        }
          </div>
          <div onClick={()=> handleClose()} id="fullView">
            <img src={arr[PreviewImage]} alt="" />
            <button  >Close</button>
          </div>
        </div>
        </>
      )
}

export default Hero