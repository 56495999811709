import React from "react";
import { useEffect } from "react";
import "./services.css";
import ceiling from "../images/ceiling.png";
import flooring from "../images/flooring.png";
import painting from "../images/painting.png";
import plumbing from "../images/plumbing.png";
import firesafety from "../images/firesafety.png";
import electrical from "../images/electrical.png";
import partition from "../images/partition.png";
import table from "../images/table.png";
import door from "../images/door.png";
import reilings from "../images/reilings.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SERVICES = () => {
  let string =
    "We take great pride in our craftsmanship, using premium products and materials to create solutions that are built to last.";
  let Texts = string.split("").map((word) => 
    <span className="Servicestext">{word}</span>
  );

  useEffect(() => {
    const Servicestext = document.querySelectorAll(".Servicestext");
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: Servicestext,
        scrub: 1,
        start: "top 80%",
        end: "top 40%",
      }
    })
    tl.to(Servicestext, {color: '#fff', ease: 'none', stagger: .1})
  }, []);
  return (
    <div id="servicescontainer">
      <div id="servicesHeading">
        <span>Services</span>
        <span id="ServicesText">{Texts}</span>
      </div>
      <div id="Offerings">
        <div>
          <img src={ceiling} />
          <span>False Ceiling</span>
        </div>
        <div>
          <img src={partition} />
          <span>Partition & Paneling</span>
        </div>
        <div>
          <img src={table} />
          <span>Furniture</span>
        </div>
        <div>
          <img src={flooring} />
          <span>Flooring</span>
        </div>

        <div>
          <img src={painting} />
          <span>Painting</span>
        </div>
        <div>
          <img src={door} />
          <span>Door & Windows</span>
        </div>

        <div>
          <img src={reilings} />
          <span>Steel Fabrication</span>
        </div>
        <div>
          <img src={plumbing} />
          <span>Sanitary & Plumbing</span>
        </div>

        <div>
          <img src={electrical} />
          <span>Electrical</span>
        </div>

        <div>
          <img src={firesafety} />
          <span>Fire Safety</span>
        </div>
      </div>
    </div>
  );
};

export default SERVICES;
