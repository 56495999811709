import React, { useRef, useState, useEffect } from "react";
import "./project.css";
import rightarrow from "../images/right-arrow.png";
import video from "./Projectimages/VarunVermaHomeInterior.mp4";
import ganesha from "./Projectimages/ganesha/4.png";
import nipman from "./Projectimages/nipman/Nipman Bawal-7.JPG";
import dwarka from "./Projectimages/dwarka/westendfarms2.jpg";
import hero from "./Projectimages/hero/heroimg1.jpeg";
import raw from "./Projectimages/raw/raw1.jpg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Timeline } from "gsap/gsap-core";
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const PROJECTS = ({ allprops }) => {

  const reelref = useRef(null);
  const [Opacity, setOpacity] = useState(false)

  const handlePlay = () => {
    reelref.current.controls = true;
    reelref.current.play();
    reelref.current.requestFullscreen();
    setOpacity(true)
  };
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setTimeout(()=>{
        reelref.current.scrollIntoView({ behavior: "smooth"})
      },1000)
        setOpacity(false)
        reelref.current.pause();
        reelref.current.currentTime = 0;
    reelref.current.controls = false;

    }
  };



  useEffect(() => {
    setTimeout(() => {
      const Projects = document.querySelectorAll("#Projects");
      const Images = document.querySelectorAll(".Images");
      let tlnew = gsap.timeline({
        scrollTrigger: {
          trigger: Images,
          // markers: true,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
        },
      });
      tlnew.fromTo(
        Images,
        { y: "20%" },
        { y: "0%", ease: "none", stagger: 0.2 }
      );
    }, 500);
  }, []);

  return (
    <>
      <div ref={allprops.projectcontainer} id="projectcontainer">
        <div id="projectheading">
          <span>Projects</span>
          <span>Explore our amazing projects, from idea to done.</span>
        </div>
        <div id="Projects">
          <div className="ProjectCards">
            <img className="Images" src={ganesha} alt="" />
            <div>
              <span>Commercial</span>
              <span>2023</span>
            </div>
            <div>
              <span>Ganesha Ecoshepere ltd.</span>
              <Link to="/ganesha" className="visitbtn">
                Visit
              </Link>
            </div>
          </div>
          <div className="ProjectCards">
            <img className="Images" src={nipman} alt="" />
            <div>
              <span>Commercial</span>
              <span>2023</span>
            </div>
            <div>
              <span>Nipman</span>
              <Link to="/nipman" className="visitbtn">
                Visit
              </Link>
            </div>
          </div>
          <div className="ProjectCards">
            <img className="Images" src={dwarka} alt="" />
            <div>
              <span>Residential</span>
              <span>2023</span>
            </div>
            <div>
              <span>Rajokari Farms</span>
              <Link to="/farm" className="visitbtn">
                Visit
              </Link>
            </div>
          </div>
          <div className="ProjectCards">
            <img className="Images" src={hero} alt="" />
            <div>
              <span>Commercial</span>
              <span>2023</span>
            </div>
            <div>
              <span>Brij Hero Mathura</span>
              <Link to="/hero" className="visitbtn">
                Visit
              </Link>
            </div>
          </div>
          <div className="ProjectCards">
            <img className="Images" src={raw} alt="" />
            <div>
              <span>Residential</span>
              <span>2023</span>
            </div>
            <div>
              <span>Verma's Residence</span>
              <Link to="/verma" className="visitbtn">
                Visit
              </Link>
            </div>
          </div>
        </div>
        <div id="reelContainer">
          <div id="reelHeading">
            <span>From Vision To Reality</span>
            <span>
              Watch our satisfied customers share their experiences! Discover
              how our product/service transformed their lives, solved problems,
              and exceeded expectations.
            </span>
          </div>
          <div id="Reel">
            <button
              onClick={handlePlay}
              id="PlayReel"
              className="playReel"
              style={{opacity: Opacity?'0':'1'}}
            >
              Play
            </button>
            <video
              id="reelVideo"
              ref={reelref}
              src={video}
            ></video>
          </div>
        </div>
      </div>
    </>
  );
};

export default PROJECTS;
