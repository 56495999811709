import React, { useEffect, useState } from "react";
import "../gallery.css";
import ganesha1 from "../Projectimages/ganesha/4.png";
import ganesha2 from "../Projectimages/ganesha/7.jpg";
import ganesha3 from "../Projectimages/ganesha/31.jpg";
import ganesha4 from "../Projectimages/ganesha/8.png";
import ganesha5 from "../Projectimages/ganesha/14.png";
import ganesha6 from "../Projectimages/ganesha/17.jpg";
import ganesha7 from "../Projectimages/ganesha/18.png";
import ganesha8 from "../Projectimages/ganesha/21.png";
import ganesha9 from "../Projectimages/ganesha/26.png";
import ganesha10 from "../Projectimages/ganesha/28.png";
import ganesha11 from "../Projectimages/ganesha/31.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 


const Ganesha = ({ allprops }) => {
  useEffect(()=>{
    allprops.setRedirect(true)
    return ()=>{
      allprops.setRedirect(false)
    }
  },[])
  useEffect(()=>{
    window.scrollTo(0,0)

    const Images_img_1 = document.querySelector('#Images-img-1')
    const firstImage = document.querySelector('.firstImage')
    let tl2 = gsap.timeline({
      scrollTrigger:{
        trigger: Images_img_1,
        scrub: 1,
        start: 'top 50%',
        end: 'bottom top'
      }
    })
    tl2.to(firstImage,{scale: 1.3, ease:'none'})
 
 
  },[])

  const [PreviewImage, setPreviewImage] = useState()
  let arr = [ganesha7 ,ganesha3 ,ganesha4 ,ganesha5 ,ganesha2 ,ganesha6 ,ganesha8 ,ganesha9 ,ganesha10 ,ganesha11]

  const handleClick = (index) =>{
    setPreviewImage(index)
    let fullView = document.querySelector('#fullView')
    gsap.to(fullView,{opacity: '1', pointerEvents: 'all'})
  }
  const handleClose = () =>{
    let fullView = document.querySelector('#fullView')
    gsap.to(fullView,{opacity: '0', pointerEvents: 'none'})
    console.log('working')
  }

  return (
    <>
    <div id="ImagesContainer">
      <div id="ProjectDescription">
        <span>Ganesha Ecopsphere Limited</span>
        <span>Commercial</span>
      </div>
      <div id="Images-img-1"><img className="firstImage" src={ganesha1} alt="" /></div>
      <div  id="HeroImages">
        {
        arr.map((item, index)=>{
          return(
            <div onClick={()=>handleClick(index)} className="heroimages"> <div>View</div> <img className='imgHero' src={item} alt="" /></div>
          )
        })
        }
          </div>
          <div onClick={()=> handleClose()} id="fullView">
            <img src={arr[PreviewImage]} alt="" />
            <button  >Close</button>
          </div>
    </div>
    </>
  );
};

export default Ganesha;
