import React, { useRef } from 'react'
import gsap from 'gsap'
import './loading.css'

const Loading = ({allprops}) => {

    const LoadingScreenRef = useRef(null)
const LoadingScreen = LoadingScreenRef.current
let tl = gsap.timeline()
if(allprops.Loading){
  tl.to(LoadingScreen,{zIndex: 1000, ease: 'none', duration: 0})
  .to(LoadingScreen,{opacity: 1, ease: 'none', duration: 0.3})
  setTimeout(()=>{
    allprops.setLoading(false)
  },1000)

}else{
  tl.to(LoadingScreen, {opacity: 0, ease: 'none', duration: 0.3})
  .to(LoadingScreen, {zIndex: -1000, ease: 'none', duration: 0})
}
  return (
    <div ref={LoadingScreenRef} id="LoadingScreen"></div>
  )
}

export default Loading