import React from "react";
import "./home.css";
import DisplayImage1 from "./Projectimages/hero/heroimg3.jpeg";
import video from "./Projectimages/VarunVermaHomeInterior.mp4";
import textvideo from "../images/textvideo.mp4";
import arrow from "../images/arrow.png"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
gsap.registerPlugin(ScrollTrigger);

const HOME = ({ allprops }) => {
  useEffect(() => {
    const DisplayImage = document.querySelector("#DisplayImage1 img");
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: DisplayImage,
        start: "top 50%",
        end: "bottom top",
        scrub: true,
      },
    });
    tl.to(DisplayImage, { scale: 1.5, ease: "none" });
  }, []);

  return (
    <>
      <div id="homepage">
        <div id="Homeheading">
          <span>
            Custom Crafted Furniture and Interior Design Solutions
          </span>
          <img src={arrow} alt="" id="homeArrow" />
        
        </div>
        <div id="DisplayImage1">
          <img src={DisplayImage1} />
        </div>
      </div>

      {/* <div id="title2">
      Our adept team of highly skilled craftsmen is committed to upholding
      impeccable standards of quality and workmanship. This commitment is
      bolstered by our dedicated support staff, ensuring punctual project
      delivery.
    </div> */}

      {/* <div id="videopage">
    <video src={video}  autoPlay loop controls muted>
     </video>
      <div id="videodescription">
        <span>From <span style={{color: '#569bd5'}}>Vision</span> <br /> To Reality</span>
        <span
          >Watch our satisfied customers share their experiences! Discover how
          our product/service transformed their lives, solved problems, and
          exceeded expectations. </span>
      </div>
    </div> */}
    </>
  );
};

export default HOME;
