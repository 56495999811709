import React from 'react'
import { useEffect, useState } from 'react';
import "../gallery.css";
import raw1 from "../Projectimages/raw/raw1.jpg";
import raw2 from "../Projectimages/raw/raw2.jpg";
import raw3 from "../Projectimages/raw/raw3.jpg";
import raw4 from "../Projectimages/raw/raw4.jpg";
import raw5 from "../Projectimages/raw/raw5.jpg";
import raw6 from "../Projectimages/raw/raw6.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

const Verma = ({allprops}) => {  
  useEffect(()=>{
    allprops.setRedirect(true)
    return ()=>{
      allprops.setRedirect(false)
    }
  },[])
  useEffect(()=>{
    window.scrollTo(0,0)

    const Images_img_1 = document.querySelector('#Images-img-1')
    const firstImage = document.querySelector('.firstImage')
    let tl = gsap.timeline({
      scrollTrigger:{
        trigger: Images_img_1,
        scrub: 1,
        start: 'top 50%',
        end: 'bottom top'
      }
    })
    tl.to(firstImage,{scale: 1.3, ease:'none'})
      },[])

      const [PreviewImage, setPreviewImage] = useState()
      let arr = [raw1, raw2, raw3, raw4, raw5, raw6]
    
      const handleClick = (index) =>{
        setPreviewImage(index)
        let fullView = document.querySelector('#fullView')
        gsap.to(fullView,{opacity: '1', pointerEvents: 'all'})
      }
      const handleClose = () =>{
        let fullView = document.querySelector('#fullView')
        gsap.to(fullView,{opacity: '0', pointerEvents: 'none'})
        console.log('working')
      }
    

  return (
    <>
    <div id="ImagesContainer">
      <div id="ProjectDescription">
        <span>Verma's Residence</span>
        <span>Residential</span>
      </div>
      <div id="Images-img-1"><img className='firstImage'  src={raw4} alt="" /></div>
      <div  id="HeroImages">
      {
        arr.map((item, index)=>{
          return(
            <div onClick={()=>handleClick(index)} className="heroimages"> <div>View</div> <img className='imgHero' src={item} alt="" /></div>
          )
        })
        }
          </div>
          <div onClick={()=> handleClose()} id="fullView">
            <img src={arr[PreviewImage]} alt="" />
            <button  >Close</button>
          </div>
    </div>
    </>
    )
  }

export default Verma