import React from 'react'
import { useEffect, useState } from 'react';
import "../gallery.css";
import Bawal1 from "../Projectimages/nipman/Nipman Bawal-1.JPG";
import Bawal2 from "../Projectimages/nipman/Nipman Bawal-2.JPG";
import Bawal3 from "../Projectimages/nipman/Nipman Bawal-3.JPG";
import Bawal4 from "../Projectimages/nipman/Nipman Bawal-4.JPG";
import Bawal5 from "../Projectimages/nipman/Nipman Bawal-5.JPG";
import Bawal6 from "../Projectimages/nipman/Nipman Bawal-6.JPG";
import Bawal7 from "../Projectimages/nipman/Nipman Bawal-7.JPG";
import Bawal8 from "../Projectimages/nipman/Nipman Bawal-8.jpg";
import Bawal9 from "../Projectimages/nipman/Nipman Bawal-9.jpg";
import Bawal10 from "../Projectimages/nipman/Nipman Bawal-10.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

const Nipman = ({allprops}) => { 
  useEffect(()=>{
    allprops.setRedirect(true)
    return ()=>{
      allprops.setRedirect(false)
    }
  },[])
  useEffect(()=>{
    window.scrollTo(0,0)

    const Images_img_1 = document.querySelector('#Images-img-1')
    const firstImage = document.querySelector('.firstImage')
    let tl = gsap.timeline({
      scrollTrigger:{
        trigger: Images_img_1,
        scrub: 1,
        start: 'top 50%',
        end: 'bottom top'
      }
    })
    tl.to(firstImage,{scale: 1.3, ease:'none'})
      },[])

      const [PreviewImage, setPreviewImage] = useState()
      let arr = [Bawal2, Bawal4, Bawal6, Bawal9, Bawal5, Bawal3, Bawal8, Bawal1, Bawal10]
    
      const handleClick = (index) =>{
        setPreviewImage(index)
        let fullView = document.querySelector('#fullView')
        gsap.to(fullView,{opacity: '1', pointerEvents: 'all'})
      }
      const handleClose = () =>{
        let fullView = document.querySelector('#fullView')
        gsap.to(fullView,{opacity: '0', pointerEvents: 'none'})
        console.log('working')
      }
    

return (
  <>
  <div id="ImagesContainer">
    <div id="ProjectDescription">
      <span>NIPMAN</span>
      <span>Office</span>
    </div>
    <div id="Images-img-1"><img className='firstImage'  src={Bawal7} alt="" /></div>
          <div  id="HeroImages">
      {
        arr.map((item, index)=>{
          return(
            <div onClick={()=>handleClick(index)} className="heroimages"> <div>View</div> <img className='imgHero' src={item} alt="" /></div>
          )
        })
        }
          </div>
          <div onClick={()=> handleClose()} id="fullView">
            <img src={arr[PreviewImage]} alt="" />
            <button  >Close</button>
          </div>
  </div>
  </>
  )
}

export default Nipman