import React, {useEffect, useRef } from 'react'
import './A2.css'
import logo from '../images/logo.jpeg'  
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

const A2 = () => {


  return (
    <>
    <div id='loadingscreen'>
      <img id='loadingscreenchild' src={logo}/>
      <svg id='loadingsvg'>
          <circle cx="140" cy="140" r={window.innerWidth >= 420 ? '140': '100'}></circle>
          </svg>
    </div>

    </>

  )
}

export default A2